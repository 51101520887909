<template>
  <div class="hello">
    <div class="masthead">
      <div
        class="
          container container-fluid
          px-4 px-lg-5
          dd-flex
          h-100
          align-items-center
          justify-content-center
        "
      >
        <div>
          <div class="half1" id="half1"></div>
          <div class="bg-white half2">
            <p v-if="post.fechaPublicacion">
              Publ. {{ moment(new Date(post.fechaPublicacion), "MMM dd") }}
              {{ lang == "es" ? "por" : "by" }} {{ post.nombreAutor }}
              <a class="float-right text-bdlg" href="#/blog">
                <span v-if="lang == 'es'">Atrás</span>
                <span v-else>Back</span>
              </a>
            </p>
            <p>&nbsp;</p>
            <div
              v-if="post.copy[lang].contenido"
              v-html="post.copy[lang].contenido"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.blog-container {
  display: block;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
}

.half1 {
  width: 50vw;
  height: 100vh;
  background-color: #cccccc;
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 3em;
  padding-top: 70px;
  overflow-y: auto;
  border: 2px solid #cccccc;
}

.half2 {
  width: 50vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 50vw;
  padding: 4em;
  padding-top: 80px;
  overflow-y: auto;
}

@media (max-width: 600px) {
  .half1 {
    display: none;
  }

  .half2 {
    width: 100vw;
    left: 0vw;
  }
}
</style>
<script>
import Api from "@/components/Api";
import axios from "axios";
import { format } from "date-fns";
export default {
  name: "areas",
  props: {
    id: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      lang: Api.obtenerLocal("lang") || "es",
      cdn: Api.cdn,
      url: Api.servidor,
      empresa: Api.obtenerLocal("bdlg.mx.empresa") || {},
      esMovil: Api.esMovil(),
      post: {
        copy: {
          es: {
            contenido: null,
          },
          en: {
            contenido: null,
          },
        },
        //imagen: 'http://placeimg.com/1000/1000/people/grayscale'
      },
    };
  },
  async mounted() {
    const id = this.id;
    const url = this.url;
    try {
      console.log("S", url);
      const post = (await axios.post(url + "/readbypass/post")).data.filter(
        (u) => u._id == id
      )[0];
      const autor = (
        await axios.post(url + "/readbypass/usuarioweb")
      ).data.filter((u) => u._id == post.idAutor)[0];
      post.nombreAutor = autor.nombre;
      this.post = post;
      let half1 = document.getElementById("half1");
      console.log("half1?", half1);
      half1.style.backgroundImage =
        "url('" + this.cdn + this.post.imagen + "')";
      console.log("post?", id, post);
    } catch (e) {
      window.location.history(-1);
    }
  },
  methods: {
    moment(a, b) {
      return format(a, b);
    },
  },
};
</script>
